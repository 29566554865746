<template>
  <div class="xac-trung">
    <b-table-simple v-if="Object.keys(data).length > 0">
      <b-thead class="text-sub">
        <b-tr>
          <b-th>Kiểu</b-th>
          <b-th class="text-right">
            <b-form-checkbox
              v-if="isShowCheckSwitchXac"
              v-model="isShowPointXac"
              name="check-button"
              switch
            >
              Xác
            </b-form-checkbox>
            <span v-else> Xác </span>
          </b-th>
          <b-th class="text-right" v-if="showAmount">Thực Thu</b-th>
          <b-th class="text-right">Trúng</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(value, name, idx) in data" :key="idx">
          <b-td class="type">{{ name }}</b-td>
          <b-td class="info"
            >{{ formatMoney(value.turn_over) }}
            <small v-if="isShowPointXac">
              ({{ formatPoint(value.turn_over_point) }}n)</small
            >
          </b-td>
          <b-td v-if="showAmount" class="info">{{
            formatMoney(value.amount)
          }}</b-td>
          <b-td class="info"
            >{{ formatMoney(value.win) }} ({{
              formatPoint(value.win_point)
            }}n)</b-td
          >
        </b-tr>
      </b-tbody>
      <slot name="tfoot">
        <b-tfoot>
          <b-tr>
            <b-th class="font-italic font-weight-light">
              {{ bet_time }}
            </b-th>
            <b-th class="info">
              {{ formatMoney(summary.turn_over) }}
              <small v-if="isShowPointXac">
                ({{ formatPoint(summary.turn_over_point) }}n)</small
              >
            </b-th>
            <b-th class="info" v-if="showAmount">
              {{ formatMoney(summary.amount) }}
            </b-th>
            <b-th class="info">
              {{ formatMoney(summary.win) }} ({{
                formatPoint(summary.win_point)
              }}n)
            </b-th>
          </b-tr>
          <b-tr>
            <b-td colspan="2">
              <div v-if="number_win != ''" class="number-win">
                <span
                  v-for="(nw, index_nw) in number_win.split(' ')"
                  :key="index_nw"
                >
                  {{ nw }}
                </span>
              </div>
            </b-td>
            <b-td colspan="2" class="text-right summary">
              <div>
                <span class="text-danger">Ăn |</span>
                <span class="text-primary"> Thua</span>:
                <b v-html="format(winloss)"></b>
              </div>
              <div v-if="playerPercent != 0" class="mt-1">
                <span>Theo {{ playerPercent }}%</span>:
                <b v-html="format((winloss * playerPercent) / 100.0)"></b>
              </div>
            </b-td>
          </b-tr>
        </b-tfoot>
      </slot>
    </b-table-simple>
  </div>
</template>
<script>
import { Utilities } from "@/utilities";

export default {
  name: "xac-trung",
  props: {
    data: {
      type: Object,
      default: () => {},
      description: ""
    },
    isShowCheckSwitchXac: {
      type: Boolean,
      default: true
    },
    showAmount: {
      type: Boolean,
      default: false
    },
    number_win: {
      type: String,
      default: ""
    },
    winloss: {
      default: ""
    },
    bet_time: {
      default: ""
    },
    playerPercent: {
      default: 0
    }
  },
  data() {
    return {
      isShowPointXac: false
    };
  },
  computed: {
    summary: function() {
      let sum = {
        turn_over: 0,
        turn_over_point: 0,
        amount: 0,
        win: 0,
        win_point: 0
      };
      for (let k in this.data) {
        let item = this.data[k];
        sum.turn_over += item.turn_over;
        sum.turn_over_point += item.turn_over_point;
        sum.amount += item.amount;
        sum.win += item.win;
        sum.win_point += item.win_point;
      }
      return sum;
    }
  },
  watch: {
    data: function(newData) {
      console.log(newData);
    }
  },
  methods: {
    fomatNumberWin(number_win) {
      return number_win.split(" ");
    },
    formatMoney(money) {
      return Utilities.currencyReport(money);
    },
    formatPoint(point) {
      return Utilities.thousandFormat(point);
    },
    format(money) {
      let cls = money < 0 ? "text-danger" : "text-primary";
      let m =
        "<span class='" +
        cls +
        "'>" +
        Utilities.currencyReport(money) +
        "</span>";
      return m;
    }
  }
};
</script>
<style>
.xac-trung {
  font-size: 0.8rem;
}
.xac-trung .table th,
.xac-trung .table td {
  padding: 0.3rem;
}
.xac-trung .table {
  margin-bottom: 0;
}
.xac-trung .info {
  text-align: right;
}
.xac-trung .type {
  text-align: left;
}
.xac-trung .number-win span {
  display: grid;
}
</style>
