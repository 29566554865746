<template>
  <b-container class="bet-sms">
    <div class="px-3">
      <b-row class="filter">
        <b-col cols="1" class="d-sm-none">
          <button-back
            v-if="origin == 'new'"
            :link="{ name: 'betList', query: { date: date } }"
          ></button-back>
          <button-back
            v-if="origin == 'betList'"
            :link="{ name: 'betList', query: { from: from, to: to } }"
          ></button-back>
          <button-back
            v-if="origin == 'reportPlayerDetail'"
            :link="{
              name: 'reportPlayerDetail',
              params: { playerId: customer_selected },
              query: { from: from, to: to }
            }"
          ></button-back>
        </b-col>
        <b-col cols="11" class="text-center text-light_ d-sm-none">
          <h4 class="mb-0 text-muted">Nhập tin</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="7" sm="6" cols="6">
          <b-row>
            <b-col md="4" sm="6" cols="12" class="select_box">
              <b-form-select
                size="sm"
                class="mb-2"
                v-model="parse_selected"
                :options="parse_opt"
              ></b-form-select>
            </b-col>
            <b-col md="4" sm="6" cols="12">
              <b-form-select
                size="sm"
                class="mb-2"
                v-model="game_selected"
                :options="games"
              ></b-form-select>
            </b-col>
            <b-col md="4" cols="12" class="mb-2">
              <date-range
                :from="date"
                :to="date"
                calendarMode="single"
                @date-change="dateChange"
              ></date-range>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" sm="6" cols="6">
          <b-row>
            <b-col md="6" sm="6" cols="12" class="select_box">
              <b-form-group id="input-group-3" class="mb-0">
                <label for="input-customer-1" class="label-sm">Khách 1:</label>
                <v-select
                  :options="customers"
                  size="sm"
                  id="input-customer-1"
                  v-model="customer_selected"
                  :reduce="option => option.value"
                ></v-select>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              sm="6"
              cols="12"
              class="select_box"
              v-if="sms_id == 0"
            >
              <b-form-group id="input-group-3">
                <label for="input-customer-2" class="label-sm"
                  >Khách 2 (nếu có):</label
                >
                <v-select
                  :options="customersTwo"
                  size="sm"
                  id="input-customer-1"
                  v-model="customer_selected_two"
                  :reduce="option => option.value"
                ></v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-card-group dec>
      <b-card class="mb-0 card-editor card-border-top">
        <div>
          <div
            id="sms-editor"
            class="mb-2 m-input card-border-top"
            style="
              padding: 5px;
              text-align: left;
              min-height: 130px;
              word-wrap: break-word;
            "
            contentEditable="true"
          ></div>
          <div
            class="btn-group w-100 mb-2"
            v-if="currentUser.role[0] == 'user'"
          >
            <b-button
              size="sm"
              class="mr-2"
              variant="success"
              v-on:click="sendCheck"
              >Kiểm tra</b-button
            >
            <b-button
              size="sm"
              class="ml-2"
              variant="primary"
              v-on:click="sendBets"
              >Lưu</b-button
            >
            <b-button
              size="sm"
              class="ml-2"
              variant="danger"
              v-on:click="clearSms"
              >Xóa</b-button
            >
          </div>
          <div class="msg-error">
            <span
              class="text-danger"
              v-for="(err, index) in error"
              :key="index"
              >{{ err }}</span
            >
          </div>
        </div>
      </b-card>
      <b-card>
        <xac-trung
          class="card-border-bottom"
          :data="totals"
          :winloss="winloss"
          :showAmount="true"
          :isShowCheckSwitchXac="true"
        >
        </xac-trung>
      </b-card>
    </b-card-group>
    <b-card-group>
      <b-card no-body v-if="number_win != ''" class="number-win">
        <b-row no-gutters>
          <b-col cols="2">
            <b-icon
              icon="star-half"
              scale="2.5"
              variant="secondary"
              class="number-win-icon"
            ></b-icon>
          </b-col>
          <b-col cols="10">
            <b-card-body class="text-center">
              <b-card-text v-for="(nw, indexnw) in number_win" :key="indexnw">
                {{ nw }}
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      <template v-if="bets.length > 0">
        <b-card v-for="(bet, indexBet) in bets" :key="indexBet" class="mb-1">
          <b-table borderless :items="bet" :fields="fieldsDetail">
            <template #thead-top="">
              <b-tr>
                <b-th colspan="5" class="text-center text-sub"
                  ><span>{{ bet[0] ? bet[0].game : "" }}</span></b-th
                >
              </b-tr>
            </template>
            <template #head()="data">
              <span class="text-sub">{{ data.label }}</span>
            </template>
          </b-table>
        </b-card>
      </template>
    </b-card-group>
    <b-overlay :show="players.length == 0" no-wrap>
      <template #overlay>
        <div
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p>
            <strong id="form-confirm-label"
              >Bạn phải tạo khách hàng để đặt cược !</strong
            >
            <strong id="form-confirm-label"
              >Đồng ý để chuyển sang trang tạo khách hàng</strong
            >
          </p>
          <div>
            <b-button variant="outline-success" :to="{ name: 'customers' }"
              >Đồng ý</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
  </b-container>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import XacTrung from "@/components/XacTrung";
import { Utilities } from "@/utilities";
import DateRange from "@/components/DateRange";

export default {
  components: {
    "xac-trung": XacTrung,
    "date-range": DateRange
  },
  computed: {
    ...mapGetters(["currentUser", "parseTypes", "players"]),
    summaryBet: function() {
      let sum = { turn_over: 0, win: 0, amount: 0, win_point: 0 };
      for (let k in this.totals) {
        let item = this.totals[k];
        sum.turn_over += item.turn_over;
        sum.win += item.win;
        sum.win_point += item.win_point;
        sum.amount += item.amount;
      }
      return sum;
    }
  },
  data() {
    return {
      sms: "",
      mien_bac: false,
      totals: {},
      bets: [],
      number_win: [],
      winloss: "",
      parse_opt: [],
      parse_selected: 2,
      customers: [],
      customersTwo: [],
      customer_selected: 0,
      customer_selected_two: "",
      games: [
        { value: 1, text: "Miền Bắc" },
        { value: 2, text: "Miền Trung" },
        { value: 3, text: "Miền Nam" }
      ],
      game_selected: 3,
      sms_id: 0,
      origin: "new",
      error: [],
      date: moment().format("YYYY-MM-DD"),
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      fieldsDetail: [
        {
          key: "channel",
          label: "Đài"
        },
        {
          key: "number",
          label: "Số"
        },
        {
          key: "code",
          label: "Kiểu"
        },
        {
          key: "point",
          label: "Điểm"
        },
        {
          key: "amount",
          label: "Tiền"
        }
      ]
    };
  },
  created() {
    this.bindDateBack();
    this.initParseType();
    this.initPlayer();
    if (this.$route.query.smsid) {
      this.sms_id = this.$route.query.smsid;
      this.origin = this.$route.query.origin;
      this.getDataSmmBet(this.$route.query.smsid);
    }
  },
  mounted() {
    let vm = this;

    document.getElementById("sms-editor").addEventListener(
      "input",
      () => {
        vm.sms = document.getElementById("sms-editor").innerText;
      },
      false
    );
  },
  watch: {
    players: function(players) {
      console.log(players);
      this.customers = players.map(function(e) {
        return { value: e.id, label: e.name, parse: e.parse };
      });
    },
    customers: function(customers) {
      if (customers.length > 0) {
        const customer_bet = localStorage.getItem("customer_bet");
        if (customer_bet) {
          this.customer_selected = parseInt(customer_bet);
        } else {
          this.customer_selected = customers[0].value;
        }
      }
      let cs = this.customer_selected;
      this.customersTwo = customers.filter(function(e) {
        return e.value != cs;
      });
    },
    customer_selected: function(select) {
      if (!isNaN(select)) {
        localStorage.setItem("customer_bet", select);

        this.parse_selected = this.customers.find(x => x.value === select).parse;
        this.customer_selected_two = "";
        let cs = this.customer_selected;
        this.customersTwo = this.customers.filter(function(e) {
          return e.value != cs;
        });
      }
    },
    parseTypes: function(parse_types) {
      if (parse_types.length > 0) {
        this.parse_opt = parse_types.map(function(e) {
          return { value: e.id, text: e.name };
        });
      }
    },
    date: function(newVal) {
      console.log(newVal);
    }
  },
  methods: {
    initParseType() {
      this.parse_opt = this.parseTypes.map(function(e) {
        return { value: e.id, text: e.name };
      });
    },
    initPlayer() {
      this.customers = this.players.map(function(e) {
        return { value: e.id, label: e.name, parse: e.parse };
      });
    },
    updateProfile() {
      alert("Your data: " + JSON.stringify(this.user));
    },
    async getDataSmmBet(smsId) {
      let vm = this;

      let res = await this.$http.get("/bets/data/" + smsId);

      if (res.data.code == 0) {
        vm.sms = res.data.data.sms;
        document.getElementById("sms-editor").innerText = res.data.data.sms;

        vm.customer_selected = res.data.data.player_id;
        vm.date = moment(res.data.data.bet_time, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        );
        vm.totals = res.data.data.info;
        vm.bets = res.data.data.details;
        vm.number_win = res.data.data.number_win.split(" ");
        vm.winloss = res.data.data.winloss;
        vm.parse_selected = res.data.data.parse_type;
        vm.game_selected = res.data.data.game_id;
      } else {
        vm.error = res.data.msg;
      }
    },
    async sendCheck() {
      let vm = this;

      if (vm.currentUser.is_lock_money === true) {
          vm.showMsgDebtor();
          return false;
      }

      let res = await this.$http.post("/bets/check", {
        game: vm.game_selected,
        date: vm.date,
        player: vm.customer_selected,
        sms: vm.sms,
        type: vm.parse_selected
      });
      if (res.data.code == -12) {
        vm.makeToast("Lỗi", res.data.msg, "danger", true);
      } else {
        vm.sms = res.data.data.sms;
        document.getElementById("sms-editor").innerText = res.data.data.sms;

        if (res.data.code == 0) {
          vm.totals = res.data.data.info;
          vm.bets = res.data.data.details;

          vm.number_win =
            res.data.data.number_win == ""
              ? []
              : res.data.data.number_win.split(" ");
          vm.winloss = res.data.data.winloss;

          vm.error = [];
        } else {
          vm.totals = {};
          vm.bets = [];
          vm.number_win = [];
          vm.winloss = "";
          vm.error = res.data.msg;
          vm.hightlightText("sms-editor", res.data.data.error);
        }
      }
    },
    async sendBets() {
      let vm = this;

      if (vm.currentUser.is_lock_money === true) {
          vm.showMsgDebtor();
          return false;
      }

      if (vm.sms == "") {
        vm.makeToast("Lỗi".total, "Hãy nhập tin nhắn !", "warning", true);
      } else {
        let res = await this.$http.post("/bets/sms", {
          game: vm.game_selected,
          date: vm.date,
          player: vm.customer_selected,
          player_two: vm.customer_selected_two,
          sms: vm.sms,
          sms_id: vm.sms_id,
          type: vm.parse_selected
        });
        if (res.data.code == -12) {
          vm.makeToast("Lỗi", res.data.msg, "danger", true);
        } else if (res.data.code == 0) {
          // vm.totals = res.data.data.info;
          // vm.bets = res.data.data.details;
          // vm.number_win = res.data.data.number_win;
          let total = res.data.data.total;

          if (vm.origin == "betList") {
            vm.makeToast("", "Sửa thành công " + total + " !", "success", true);
            setTimeout(function() {
              vm.$router.push({ name: "betList", query: { date: vm.date } });
            }, 2000);
          } else {
            vm.makeToast(
              "",
              "Cược thành công " + total + " !",
              "success",
              true
            );
            vm.error = [];
            vm.totals = {};
            vm.bets = [];
            vm.number_win = [];
            vm.winloss = "";
            vm.sms = "";
            vm.sms_id = 0;
            document.getElementById("sms-editor").innerText = "";
          }
        } else {
          vm.totals = {};
          vm.bets = [];
          vm.number_win = [];
          vm.error = res.data.msg;
          vm.sms_id = res.data.data.sms_id;
          vm.sms = res.data.data.sms;
          document.getElementById("sms-editor").innerText = res.data.data.sms;
          vm.hightlightText("sms-editor", res.data.data.error);
        }
      }

      // if (localStorage.getItem("shown_notify_bug_10082022") != "done") {
      //   let vm = this;
      //   setTimeout(function () {
      //     vm.showNotify();
      //     localStorage.setItem("shown_notify_bug_10082022", "done");
      //   }, 1000);
      // }
    },
    hightlightText: function(editorId, words) {
      let content = document.getElementById(editorId).innerHTML;

      for (let i = 0; i < words.length; i++) {
        let w = words[i].toLowerCase();
        let re = new RegExp(w.replace(new RegExp("\\.", "g"), "\\."), "g");
        content = content.replace(
          re,
          '<span style="background-color: red">' + w + "</span>"
        );
      }

      document.getElementById(editorId).innerHTML = content;
    },
    makeToast(title, content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: title,
        autoHideDelay: 5000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right mb-5"
      });
    },
    formatMoney(money) {
      return Utilities.currencyReport(money);
    },
    formatPoint(point) {
      return Utilities.thousandFormat(point);
    },
    bindDateBack() {
      this.from = this.$route.query.from
        ? moment(this.$route.query.from, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.to = this.$route.query.to
        ? moment(this.$route.query.to, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    },
    async dateChange(date) {
      this.date = date.from;
    },
    showNotify() {
      const h = this.$createElement;
      const titleVNode = h("div", {
        domProps: { innerHTML: "[Quan trọng] Lỗi tính tiền " }
      });
      const messageVNode = h("div", { class: ["foobar px-3"] }, [
        h("p", { class: ["text-left"] }, [
          "Những tin được nhập vào hệ thống từ 11h đến 18h ngày 10-08-2022 bị sai tiền thực thu."
        ]),
        h("p", { class: ["text-left"] }, [
          "Quý khách vui lòng làm theo hướng dẫn để khắc phục lỗi"
        ]),
        h("b-img", {
          props: {
            src: "https://lienquan5.com/img/edit_sms_01.jpg",
            thumbnail: true,
            center: true,
            fluid: true
          }
        }),
        h("p", { class: ["text-left"] }, ["Bước 1: Chọn báo cáo"]),
        h("p", { class: ["text-left"] }, ["Bước 2: Chọn tin đã cược"]),
        h("b-img", {
          props: {
            src: "https://lienquan5.com/img/edit_sms_02.jpg",
            thumbnail: true,
            center: true,
            fluid: true
          }
        }),
        h("p", { class: ["text-left"] }, ['Bước 3: Chọn "Lưu"'])
      ]);
      this.$bvModal.msgBoxOk([messageVNode], {
        title: [titleVNode],
        hideHeaderClose: false
      });
    },
    clearSms() {
      this.error = [];
      this.totals = {};
      this.bets = [];
      this.number_win = [];
      this.winloss = "";
      this.sms = "";
      this.sms_id = 0;
      document.getElementById("sms-editor").innerText = "";
    },
    showMsgDebtor() {
        const h = this.$createElement;
            const titleVNode = h("div", {
                domProps: { innerHTML: "Tài khoản bị khoá !" },
            });
            
            const messageVNode = h("div", { domProps: { innerHTML: this.currentUser.msg },  class: ["foobar px-3"]});
            this.$bvModal
                .msgBoxOk([messageVNode], {
                    title: [titleVNode],
                    hideHeaderClose: false,
                    noCloseOnBackdrop: false,
                    hideFooter: true,
                })
    }
  }
};
</script>
<style>
.bet-sms .table {
  margin-bottom: 0;
}
.bet-sms .table th,
.bet-sms .table td {
  border-top: none;
}
.bet-sms .card-body {
  padding: 0.5rem;
}
.bet-sms .table thead th {
  border-bottom: 1px solid #dee2e6;
}

.bet-sms .card-group > .card {
  margin-bottom: 0;
}
/* .bet-sms .number-win{
    border: 1px solid #ffe2e5;
    background-color: #ffe2e5;
} */
.bet-sms .msg-error,
.bet-sms .number-win .card-body {
  display: grid;
}
.bet-sms .number-win .number-win-icon {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* @media (max-width: 576px){
    .bet-sms .number-win{
        margin: 5px 20px;
    }
} */
.label-sm {
  font-size: 0.8rem;
  margin: 0px;
}
</style>
